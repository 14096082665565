<mat-toolbar class="p-0 mat-elevation-z1">
    <div fxFlex fxFill fxLayout="row" fxLayoutAlign="start center">
        <div fxFlex="1 0 auto" fxLayout="row" fxLayoutAlign="start center">
            <button
                mat-icon-button
                class="navbar-toggle-button"
                *ngIf="!hiddenNavbar && !rightNavbar"
                (click)="toggleSidebarOpen('navbar')"
                fxHide.gt-md
            >
                <mat-icon class="secondary-text">menu</mat-icon>
            </button>

            <div class="toolbar-separator" *ngIf="!hiddenNavbar && !rightNavbar" fxHide.gt-md></div>

            <div fxLayout="row" fxLayoutAlign="start center" *ngIf="horizontalNavbar">
                <div class="logo ml-16">
                    <img class="logo-icon" src="assets/images/logos/fuse.svg" />
                </div>
            </div>

            <div class="px-8 px-md-16">
                <fuse-shortcuts [navigation]="navigation"></fuse-shortcuts>
            </div>
        </div>

        <div class="" fxFlex="0 1 auto" fxLayout="row" fxLayoutAlign="start center">
            <button mat-button [matMenuTriggerFor]="userMenu" class="user-button">
                <div fxLayout="row" fxLayoutAlign="center center">
                    <img ngxGravatar class="avatar mr-0 mr-sm-16" [email]="user.email" />
                    <span class="username mr-12" fxHide fxShow.gt-sm
                        >{{ user.first_name }} {{ user.last_name }}
                        {{ user.is_subuser ? '(Subuser)' : '' }}</span
                    >
                    <mat-icon class="s-16" fxHide.xs>keyboard_arrow_down</mat-icon>
                </div>
            </button>

            <mat-menu #userMenu="matMenu" [overlapTrigger]="false">
                <button mat-menu-item (click)="goToProfile()">
                    <mat-icon>account_circle</mat-icon>
                    <span>My Profile</span>
                </button>

                <button
                    [routerLink]="['/dashboard/settings']"
                    mat-menu-item
                    class=""
                    *ngIf="!user.is_subuser"
                >
                    <mat-icon>settings</mat-icon>
                    <span>Settings</span>
                </button>

                <button mat-menu-item class="" (click)="logoutClicked()">
                    <mat-icon>exit_to_app</mat-icon>
                    <span>Logout</span>
                </button>
            </mat-menu>

            <div class="toolbar-separator"></div>

            <fuse-search-bar (input)="search($event)"></fuse-search-bar>

            <div class="toolbar-separator"></div>

            <div class="toolbar-separator" fxHide fxShow.gt-xs></div>

            <button
                mat-icon-button
                class="quick-panel-toggle-button"
                (click)="toggleSidebarOpen('quickPanel')"
                aria-label="Toggle quick panel"
            >
                <mat-icon class="secondary-text">format_list_bulleted</mat-icon>
            </button>

            <div
                class="toolbar-separator"
                *ngIf="!hiddenNavbar && rightNavbar"
                fxHide
                fxShow.gt-xs
            ></div>

            <button
                mat-icon-button
                class="navbar-toggle-button"
                *ngIf="!hiddenNavbar && rightNavbar"
                (click)="toggleSidebarOpen('navbar')"
                fxHide.gt-md
            >
                <mat-icon class="secondary-text">menu</mat-icon>
            </button>
        </div>
    </div>
</mat-toolbar>
