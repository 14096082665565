import { FuseNavigation } from '@fuse/types';

export const navigation: FuseNavigation[] = [
    {
        id      : 'dash',
        title   : 'Pages',
        type    : 'group',
        icon    : 'pages',
        children: [
            {
                id   : 'dashboard',
                title: 'Dashboard',
                type : 'item',
                icon : 'dashboard',
                url  : '/dashboard/home'
            },
            {
                id   : 'bootcamp',
                title: 'Bootcamp',
                type : 'item',
                icon : 'videocam',
                url  : '/dashboard/bootcamp'
            },
            {
                id   : 'pages',
                title: 'Pages',
                type : 'item',
                icon : 'assignment',
                url  : '/dashboard/pages'
            },
            {
                id   : 'instagram',
                title: 'Instagram',
                type : 'item',
                icon : 'assignment',
                url  : '/dashboard/instagram'
            },
        ]
    }
];