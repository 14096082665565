import { AuthStateModel, Login, Refresh, Logout, SetRedirectUrl } from "app/models/auth.model";
import { Selector, Action, StateContext, State } from '@ngxs/store';
import { AuthService } from 'app/auth/auth.service';
import { tap } from 'rxjs/operators'
import { User } from 'app/user/user';
import { Injectable } from '@angular/core';

@State<AuthStateModel>({
    name: 'auth'
})

@Injectable()
export class AuthState {

    @Selector()
    static token(state: AuthStateModel) { return state.token; }

    @Selector()
    static user(state: AuthStateModel) { return state.user; }

    @Selector()
    static redirectUrl(state: AuthStateModel) { return state.redirectUrl; }

    constructor(private authService: AuthService) { }

    @Action(Login)
    login({ patchState }: StateContext<AuthStateModel>, { payload }: Login) {
        return this.authService.login(payload).pipe(tap((result: { token: string, user: User }) => {
            patchState({ token: result.token, user: result.user });
        }))
    }

    @Action(Refresh)
    refresh({ patchState }: StateContext<AuthStateModel>, { payload }: Refresh) {
        return this.authService.getUser(payload.token).pipe(tap((result: { token: string, user: User }) => {
            patchState({ token: result.token, user: result.user });
        }))
    }

    @Action(Logout)
    logout({ setState, getState }: StateContext<AuthStateModel>) {
        const { token } = getState();
        return this.authService.logout(token).pipe(tap(() => {
            setState({});
        }));
    }

    @Action(SetRedirectUrl)
    setRedirectUrl({ patchState }: StateContext<AuthStateModel>, { payload }: SetRedirectUrl) {
        patchState({ redirectUrl: payload.redirectUrl })
    }

}