<mat-toolbar>

    <div fxLayout="row" fxLayoutAlign="center center" fxLayoutAlign.gt-xs="space-between center" fxFlex>
        <div fxLayout="row" fxLayoutAlign="start center" fxHide fxShow.gt-xs>
            <a mat-button href="mailto:support@fpcommand.com?subject=FPCommand">Support</a>
            <span>&bull;</span>
            <a mat-button target="_new" href="https://www.facebook.com/groups/fpcommand/">Facebook Group</a>
        </div>

    </div>

</mat-toolbar>
