import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { User } from 'app/user/user';
import { Store } from '@ngxs/store';
import { AuthState } from 'app/state/auth.state';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Refresh } from 'app/models/auth.model';

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    constructor(private store: Store, private route: ActivatedRoute, private http: HttpClient, private jwtHlper: JwtHelperService) { }

    login(payload): Observable<{ token: string, user: User, redirectUrl: string }> {
        const token = payload.token;
        if (token !== null) {
            return this.getUser(token);
        } else {
            return throwError(new Error('token not defined'));
        }
    }

    logout(payload): Observable<null> {
        return of(null);
    }

    refreshUser() {
        if(this.isAuthenticated()) {
            this.store.dispatch(new Refresh({token: this.store.selectSnapshot(AuthState.token)})).subscribe(
                _ => {
                    console.debug('you have successfully refreshed the user');
                },
                error => {
                    console.error(error);
                }
            );
        }
    }

    getUser(token: string): Observable<{ token: string, user: User, redirectUrl: string}> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`
            })
        }

        let userUrl = `${environment.apiURL}/api/user`;
        return new Observable((observer) => {
            this.http.get(userUrl, httpOptions)
                .subscribe(
                    (res: User) => {
                        console.debug(res);
                        const redirectUrl = this.store.selectSnapshot(AuthState.redirectUrl);

                        observer.next({
                            token: token,
                            user: res,
                            redirectUrl: redirectUrl
                        })
                        observer.complete();
                    },
                    error => {
                        console.error(error);
                        observer.error(new Error(error));
                        observer.complete();
                    }
                )
        })
    }

    public isAuthenticated(): boolean {
        const token = this.store.selectSnapshot(AuthState.token);
        if (token == undefined) {
            return false;
        }
        return !this.jwtHlper.isTokenExpired(token);
    }

    public getToken(): string {
        return this.store.selectSnapshot(AuthState.token);
    }
}