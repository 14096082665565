import { NgModule } from '@angular/core';
import { NgxsModule, Store } from '@ngxs/store';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';

import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseProgressBarModule, FuseSidebarModule, FuseThemeOptionsModule } from '@fuse/components';

import { fuseConfig } from 'app/fuse-config';

import { NgxPaginationModule } from 'ngx-pagination';

import { AppComponent } from 'app/app.component';
import { LayoutModule } from 'app/layout/layout.module';
import { AuthState } from './state/auth.state';
import { AuthGuard } from './auth/auth-guard.service';
import { JwtModule } from '@auth0/angular-jwt';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { TokenInterceptor } from './auth/token.intercepter';
import { environment } from 'environments/environment';

const appRoutes: Routes = [
    {
        path: 'invite',
        loadChildren: './main/pages/authentication/invite/invite.module#InviteModule',
    },
    {
        path: 'login',
        loadChildren: './main/pages/authentication/login/login.module#LoginModule',
    },
    {
        path: 'legal',
        loadChildren: './main/pages/legal/legal.module#LegalModule',
    },
    {
        path: 'dashboard',
        canActivate: [AuthGuard],
        loadChildren: './main/pages/dashboard/dashboard.module#DashboardModule',
    },
    {
        path: '**',
        redirectTo: '/dashboard/home',
    },
];

const getToken = function (store: Store) {
    return this.store.selectedSnapshot(AuthState.token);
};

export function tokenGetter() {
    let token = localStorage.getItem('auth.token');
    return token;
}

@NgModule({
    declarations: [AppComponent],
    providers: [
        AuthGuard,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptor,
            multi: true,
        },
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        RouterModule.forRoot(appRoutes),

        NgxsModule.forRoot([AuthState], {
            developmentMode: !environment.production,
        }),

        NgxsStoragePluginModule.forRoot({
            key: ['auth.token', 'auth.user', 'auth.redirectUrl'],
        }),

        JwtModule.forRoot({
            config: {
                tokenGetter: tokenGetter,
            },
        }),

        // Angular loading bar module
        LoadingBarHttpClientModule,

        // Ngx Pagination Module
        NgxPaginationModule,

        TranslateModule.forRoot(),

        // Material
        MatButtonModule,
        MatIconModule,

        // Fuse modules
        FuseModule.forRoot(fuseConfig),
        FuseProgressBarModule,
        FuseSharedModule,
        FuseSidebarModule,
        FuseThemeOptionsModule,

        // App modules
        LayoutModule,
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
