import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { AuthService } from './auth.service';
import { Actions, ofActionDispatched, Store } from '@ngxs/store';
import { SetRedirectUrl } from 'app/models/auth.model';

@Injectable()
export class AuthGuard implements CanActivate {
    constructor(private router: Router, public authService: AuthService, public store: Store) { }

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {

        let url: string = state.url;

        return this.checkLogin(url);
    }

    checkLogin(url: string): boolean {
        if (this.authService.isAuthenticated()) {
            return true;
        }

        this.store.dispatch(new SetRedirectUrl({ redirectUrl: url })).subscribe(() => {
            console.debug("set url in store ", url)
        })

        this.router.navigate(['/login'])
        return false;
    }
}