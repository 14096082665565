import { User } from 'app/user/user';

export class AuthStateModel {
    token?: string;
    user?: User;
    redirectUrl?: string;
}

export class Login {
    static readonly type = '[Auth] Login';
    constructor(public payload: {token: string}) {}
}

export class Refresh {
    static readonly type = '[Auth] Refresh';
    constructor(public payload: {token: string}) {}
}

export class Logout {
    static readonly type = '[Auth] Logout';
}

export class SetRedirectUrl {
    static readonly type = '[Auth] SetRedirectUrl';
    constructor(public payload: {redirectUrl: string}) {}
}